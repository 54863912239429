import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultStyles from './DefaultStyles'

interface IHeaderProps {
  title?: string
}

const Header = ({ title }: IHeaderProps) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Store Master Managment'}</title>
      </Helmet>
      <DefaultStyles />
    </>
  )
}

export default Header
