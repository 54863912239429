export enum EnumConfigType {
  City_Tier = 'City_Tier',
  Area_Level = 'Area_Level',
  Store_Status = 'Store_Status',
  Store_Type = 'Store_Type',
  Store_Region = 'Store_Region',
  Store_Tier = 'Store_Tier',
  Business_Model = 'Business_Model',
  Cashier_Method = 'Cashier_Method',
  Network = 'Network',
  POS_Machine_Type = 'POS_Machine_Type',
  Position = 'Position',
  Wholesale_Type = 'Wholesale_Type',
  Wholesale_Status = 'Wholesale_Status',
  Wholesale_Level = 'Wholesale_Level',
  Excel_Export_Template = 'Excel_Export_Template',
}

export enum EnumFormFieldType {
  Select = 'Select',
  Input = 'Input',
  DatePicker = 'DatePicker',
  Cascader = 'Cascader',
  TimePicker = 'TimePicker',
  TimeRangePicker = 'TimeRangePicker',
  DateRangePicker = 'DateRangePicker',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  AutoComplete = 'AutoComplete',
}

export enum EnumMenuTypes {
  OwnStoreList = 'Own Store',
  WholesaleList = 'Wholesale',
  SpecialOpTime = 'Special OP Time',
  CityWarehouse = 'City Warehouse',
  EmployeeList = 'Employee',
  TemporaryClosedList = 'Temporary Closed',
}

export enum EnumPageUrl {
  LoginPage = 'login',
  ResetPassword = 'resetPassword',
  OwnStorePage = '/',
  WholesaleListPage = 'wholesale',
  SpecialOpTimePage = 'specialOpTime',
  EmployeeListPage = 'employee',
  CityWarehousePage = 'cityWarehouse',
  TemporaryClosedListPage = 'temporaryClosed',
}

export enum EnumOperateType {
  Add = 'add',
  Update = 'Update',
  Close = 'Close',
  Cancel = 'Cancel',
  Submit = 'Submit',
  Filter = 'Filter',
  ChangePage = 'ChangePage',
}

export enum EnumRolePostion {
  RM = 'RD', //区域零售总监
  BD = 'BD', //零售区域商务拓展经理
  CM = 'CM', //城市经理
  CS = 'CS',
  SSS = 'SSS',
}

export enum EnumExcelType {
  StoreReport = 'storeReport',
  Template = 'template',
  UploadStoreTier = 'storeTier',
  UploadStorePosition = 'storePosition',
  UploadWholesale = 'wholesale',
}

export enum EnumCookieKeys {
  AuthToken = '_dw_auth_token',
}
