import React from 'react'
import { Link as GatsbyLink, navigate, withPrefix } from 'gatsby'

const removeStartAndEndSlashes = (relativeLink) => {
  return relativeLink.replace(/^\/|\/$/g, '')
}

export const isAbsoluteLink = (href: string): boolean =>
  Boolean(href && (href.match(/^http/) || href.startsWith('//')))

export const navigateTo = (to, trailingSlash = true, props = {}) => {
  const normalizedTo = removeStartAndEndSlashes(to)
  navigate(`/${normalizedTo}${trailingSlash ? '/' : ''}`, props)
}

export const navigateToExternal = (to, trailingSlash = true) => {
  const normalizedTo = removeStartAndEndSlashes(to)
  window.location.href = `${normalizedTo}${trailingSlash ? '/' : ''}`
}

export const Link = ({ children, to, internal, ...other }) => {
  // Absolute links are transformed into A links
  if (isAbsoluteLink(to)) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }

  // When no link is provided, use an anchor link
  if (!to) {
    return (
      <a href="#dw" {...other}>
        {children}
      </a>
    )
  }

  const normalizedTo = removeStartAndEndSlashes(to)
  const path = normalizedTo ? `/${normalizedTo}/` : '/'

  // Use gatsby-link for internal links, and <a> for others
  const isExternalLink = !internal

  if (isExternalLink) {
    return (
      <a href={withPrefix(path)} {...other}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={path} {...other}>
      {children}
    </GatsbyLink>
  )
}
