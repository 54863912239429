import { createGlobalStyle } from 'styled-components';

import '../../style/fonts.css';

// eslint-disable-next-line no-unused-expressions
export default createGlobalStyle`
  :lang(ar) {
    letter-spacing: 0 !important;
  }

  html {
    /* This makes 1rem to equal 10px */
    font-size: 62.5%;
  }

  body {
    padding: 0;
    margin: 0;
  }

  .disableScroll {
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%; /* https://stackoverflow.com/questions/5303263/fix-font-size-issue-on-mobile-safari-iphone-where-text-is-rendered-inconsisten */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    outline-offset: -2px;
  }

  /*
    https://github.com/WICG/focus-visible
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  :focus:not(.focus-visible) {
    outline: none;
  }
  :focus:not(.focus-visible)::-moz-focus-inner {
    border: 0;
  }

  .ant-form-item-label > label{
    white-space: normal;
  }
`;
