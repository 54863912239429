export interface IAuthorizationContextState {
  signIn: (query: {
    username: string
    password: string
  }) => Promise<string | undefined>

  changePassword: (query: {
    username: string
    oldPassword: string
    newPassword: string
  }) => Promise<string | undefined>
}

export const initialState: IAuthorizationContextState = {
  signIn: () => undefined,
  changePassword: () => undefined,
}
